<template>
  <div>
    <!-- MENÜ -->

    <!-- HAMBURGER MENÜ v2.0 -->
    <div
      style="background-color: #464646; position: fixed; top: 0; width: 100%; z-index: 100; block;"
      class="w-full"
      v-if="isXs" 
    >
      <div class="mx-auto h-full">
        <div class="block ml-auto pa-3" v-show="isXs">
          <input type="checkbox" id="checkbox" v-model="menuOpen" />
          <label for="checkbox" class="toggle">
            <div class="bars" id="bar1"></div>
            <div class="bars" id="bar2"></div>
            <div class="bars" id="bar3"></div>
          </label>
          <div
            style="
              position: absolute;
              background-color: #464646;
              width: 100%;
              top: 0;
              left: 0;
            "
            class="left-0 top-0 text-gray-600 text-lg menu-content bg-white leading-10"
            id="mobile-menu"
            :class="{ 'menu-open': menuOpen }"
          >
            <ul class="w-[90%] mx-auto font-bold py-2">
              <li class="pt-12">
                <a href="#welcome" class="custom-btn" @click="closeMenu">Főoldal</a>
              </li>
              <li class="">
                <a href="#hazirend" class="custom-btn" @click="closeMenu">Házirend</a>
              </li>
              <li class="">
                <a href="#kezelesek" class="custom-btn" @click="closeMenu">Kezelések</a>
              </li>
              <li class="">
                <a href="#arlista" class="custom-btn" @click="closeMenu">Árlista</a>
              </li>
              <li class="">
                <a href="#elerhetosegek" class="custom-btn" @click="closeMenu"
                  >Elérhetőségek</a
                >
              </li>
            </ul>
          </div>
        </div>
      </div>
    </div>

    <!-- / HAMBURGER MENÜ v2.0 -->
    <!-- DESKTOP MENÜ -->
    <div class="sticky-menu-content" v-if="!isXs">
      <v-row class="ma-0 pa-0">
        <v-col class="d-flex justify-space-around px-auto text-center py-0">
          <v-sheet>
            <a href="#welcome" class="custom-btn">Főoldal</a>
            <a href="#hazirend" class="custom-btn">Házirend</a>
            <a href="#kezelesek" class="custom-btn">Kezelések</a>
            <a href="#arlista" class="custom-btn">Árlista</a>
            <a href="#elerhetosegek" class="custom-btn">Elérhetőségek</a>
          </v-sheet>
        </v-col>
      </v-row>
    </div>
    <!-- / DESKTOP MENÜ -->
    <!-- / MENÜ -->
  </div>
</template>

<script>
export default {
  name: "AppHeader",

  data: () => ({
    menuOpen: false,
    menuClick: false,
  }),
  computed: {
    // Ellenőrizzük, hogy az aktuális ablakméret Xs
    isXs() {
      const { xs, sm, md, lg, xl } = this.$vuetify.breakpoint;
      return !(lg || xl || md || sm); // Csak akkor igaz, ha xs, de nem nagyobb
    },
  },
  methods: {
    closeMenu() {
      this.menuOpen = false;
    },
  },
};
</script>

<style lang="scss" scoped>
ul {
  z-index: 100;
  padding-top: 15px;
  margin-top: 15px;
}
#checkbox {
  display: none;
}
.toggle {
  position: relative;
  width: 30px;
  height: 30px;
  cursor: pointer;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 5px;
  transition-duration: 0.5s;
  z-index: 999;
}
.bars {
  width: 80%;
  height: 4px;
  // background-color: rgb(73, 80, 87);
  background-color: rgb(255, 255, 255);
  border-radius: 3px;
  transition-duration: 0.5s;
  position: relative;
}
#checkbox:checked ~ .toggle #bar1 {
  transform: rotate(45deg) translate(5px, 5px);
  transform-origin: center;
  transition-duration: 0.5s;
}
#checkbox:checked ~ .toggle #bar3 {
  transform: rotate(-45deg) translate(5px, -5px);
  transform-origin: center;
  transition-duration: 0.5s;
}
#checkbox:checked ~ .toggle #bar2 {
  opacity: 0;
  height: 0;
  transform: scale(0);
  transition-duration: 0.5s;
}
.menu-content {
  opacity: 0;
  display: none;
  transform: translateY(-10%);
  transition: transform 0.6s ease, opacity 0.5s ease;
  z-index: 100;
}
.menu-content.menu-open {
  transform: translateY(0);
  opacity: 1;
  display: block;
  z-index: 100;
}
#checkbox:checked ~ .menu-content {
  transform: translateY(0);
  opacity: 1;
  display: block;
  z-index: 100;
}
ul {
  list-style-type: none;
  display: inline-block;
  padding: 0;
  margin: 0;
}
.sticky-menu-content {
  position: fixed;
  top: 0;
  width: 100vw;
  z-index: 100;
  padding-top: 0;
  // background-color: #fcfcfc;
  background-color: #464646;
  padding-bottom: 0;
}
.sticky-hamburger-content {
  position: fixed;
  width: 100wh;
  top: 0;
  z-index: 100;
  // background-color: #fcfcfc;
  background-color: #464646;
}
.custom-btn {
  display: inline-block;
  padding: 0.5rem 1rem;
  // background-color: #fcfcfcfc;
  background-color: #464646;
  color: #ffffff;
  text-decoration: none;
  // border-radius: 4px;
  text-align: center;
  font-family: "Roboto, Lucida Sans", "Lucida Sans Regular", "Lucida Grande",
    "Lucida Sans Unicode", Geneva, Verdana, sans-serif;
  text-transform: uppercase;
  font-size: 0.9rem;
  // font-weight: lighter;
  transition: background-color 0.3s ease, box-shadow 0.3s ease;
}
.custom-btn:hover {
  // background-color: #fcfcfcfc;
  background-color: #464646;
  font-weight: bold;
  // color: #6b5344;
  color: #c2a38e;
  // box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
}
.custom-btn:active {
  // background-color: #fcfcfcfc;
  background-color: #464646;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.2);
}
</style>
