<template>
  <div id="elerhetosegek">
    <v-row class="text-center">
      <v-col cols="12" ref="elerhetosegek">
        <!-- <h2 class="mx-auto mb-3 text-uppercase text-indigo-darken-2">
          <span class="text-indigo-darken-2">Elérhetőségek</span>
        </h2> -->
      </v-col>
    </v-row>
    <h3 class="text-center pt-6 font-weight-light">SOCIAL MEDIA</h3>
    <v-row class="text-center">
      <v-col class="mb-4">
        <div>
          <v-row justify="center" align="center" class="pt-6">
            <v-col class="d-flex align-center" cols="auto" sm="auto">
              <a target="_blank" href="https://www.facebook.com/shining.body.5/">
                <v-hover>
                  <v-img
                    slot-scope="{ hover }"
                    v-if="hover"
                    v-bind="attrs"
                    v-on="on"
                    :src="require('@/assets/icons8-facebook-50.png')"
                    contain
                    height="35"
                  >
                  </v-img>
                  <v-img
                    v-else
                    :src="require('@/assets/icons8-facebook-bw-50.png')"
                    contain
                    height="35"
                  >
                  </v-img>
                </v-hover>
              </a>
            </v-col>
            <v-col class="d-flex align-center" cols="auto" sm="auto">
              <a target="_blank" href="https://www.instagram.com/shiningbody_gyor/">
                <v-hover>
                  <v-img
                    slot-scope="{ hover }"
                    v-if="hover"
                    v-bind="attrs"
                    v-on="on"
                    :src="require('@/assets/icons8-instagram-50.png')"
                    contain
                    height="35"
                  >
                  </v-img>
                  <v-img
                    v-else
                    :src="require('@/assets/icons8-instagram-bw-50.png')"
                    contain
                    height="35"
                  >
                  </v-img>
                </v-hover>
              </a>
            </v-col>
            <v-col class="d-flex align-center" cols="auto" sm="auto">
              <a target="_blank" href="https://www.tiktok.com/@shiningbody_gyor">
                <v-hover>
                  <v-img
                    slot-scope="{ hover }"
                    v-if="hover"
                    v-bind="attrs"
                    v-on="on"
                    :src="require('@/assets/icons8-tiktok-50.png')"
                    contain
                    height="35"
                  >
                  </v-img>
                  <v-img
                    v-else
                    :src="require('@/assets/icons8-tiktok-bw-50.png')"
                    contain
                    height="35"
                  >
                  </v-img>
                </v-hover>
              </a>
            </v-col>
          </v-row>
        </div>
      </v-col>
    </v-row>
    <h3 class="text-center pt-6 font-weight-light">KAPCSOLAT</h3>
    <v-row class="text-center">
      <v-col class="mb-4">
        <div class="white--text">
          <v-row justify="center" align="center" class="pt-6">
            <v-col class="d-flex align-center" cols="auto" sm="auto">
              <a target="_blank" href="mailto:info@shiningbody.hu">
                <v-hover>
                  <v-img
                    slot-scope="{ hover }"
                    v-if="hover"
                    v-bind="attrs"
                    v-on="on"
                    :src="require('@/assets/icons8-email-50.png')"
                    contain
                    height="35"
                  >
                  </v-img>
                  <v-img
                    v-else
                    :src="require('@/assets/icons8-email-bw-50.png')"
                    contain
                    height="35"
                  >
                  </v-img>
                </v-hover>
              </a>
            </v-col>
            <v-col class="d-flex align-center" cols="auto" sm="auto">
              <a target="_blank" href="https://goo.gl/maps/wpegcwfoUwcG2HQi9">
                <v-hover>
                  <v-img
                    slot-scope="{ hover }"
                    v-if="hover"
                    v-bind="attrs"
                    v-on="on"
                    :src="require('@/assets/icons8-location-50.png')"
                    contain
                    height="35"
                  >
                  </v-img>
                  <v-img
                    v-else
                    :src="require('@/assets/icons8-location-bw-50.png')"
                    contain
                    height="35"
                  >
                  </v-img>
                </v-hover>
              </a>
            </v-col>
            <v-col class="d-flex align-center" cols="auto" sm="auto">
              <a href="tel:+36303179672">
                <v-hover>
                  <v-img
                    slot-scope="{ hover }"
                    v-if="hover"
                    v-bind="attrs"
                    v-on="on"
                    :src="require('@/assets/icons8-phone-50.png')"
                    contain
                    height="35"
                  >
                  </v-img>
                  <v-img
                    v-else
                    :src="require('@/assets/icons8-phone-bw-50.png')"
                    contain
                    height="35"
                  >
                  </v-img>
                </v-hover>
              </a>
            </v-col>
          </v-row>
        </div>
      </v-col>
    </v-row>
    <h3 class="text-center pt-6 font-weight-light">NYITVATARTÁS</h3>
    <v-row class="text-center">
      <v-col class="mb-4">
        <div class="black--text">
          <v-row justify="center" align="center" class="pt-6">
            <v-col class="d-flex align-center" cols="auto" sm="auto">
              H-P
              <h5 class="pl-3 font-weight-light" style="color: green">09:00-20:00</h5>
            </v-col>
            <v-col class="d-flex align-center" cols="auto" sm="auto">
              SZ-V<h5 class="pl-3 font-weight-light" style="color: red">Zárva</h5>
            </v-col>
          </v-row>
        </div>
        <v-sheet
          max-width="25%"
          class="mx-auto mt-16 text-caption black--text text-center"
          color="white"
        >
          <v-divider class="black"></v-divider>
        </v-sheet>
      </v-col>
    </v-row>
  </div>
</template>

<script>
export default {
  name: "Elerhetosegek",
  data: () => ({}),
};
</script>
<style lang="scss" scoped></style>
