<template>
  <div id="hazirend">
    <v-row class="text-center" background-color="#000fff">
      <v-col cols="12">
        <!-- <h1 class="mx-auto font-weight-light mt-2 text-uppercase">Házirend</h1> -->
      </v-col>
      <v-col class="mb-4">
        <v-sheet
          max-width="70%"
          class="mx-auto text-caption black--text text-center mb-8"
          color="white"
        >
          <h2 class="font-weight-light mb-3 text-uppercase">Érkezés</h2>
          <p class="text-caption font-weight-regular">
            A foglalt időpont az edzés/kezelés kezdetét jelenti! Kérlek Titeket, hogy 10
            perccel előbb érkezzetek! Ha késel, a saját edzésed/kezelésed idejéből veszed
            el, hiszen a következő pontosan érkező vendégemet nem várathatom meg!
          </p>

          <h2 class="font-weight-light mb-3 text-uppercase">Lemondás</h2>
          <p class="text-caption font-weight-regular">
            Minimum 24 órával előtte le kell mondani az edzést/kezelést! Más különben az
            adott alkalom elveszik! Az aznapi időpont másnapra rakása is időpont
            lemondásnak számít!
          </p>

          <h2 class="font-weight-light mb-3 text-uppercase">Időpont</h2>
          <p class="text-caption font-weight-regular">
            Kérlek Titeket, hogy előre írjuk be az időpontokat pár hétre. Szeretném, ha
            mindenki a maga rendszere szerint tudna jönni. Időpontokat egyeztetés nélkül
            nem írok be!
          </p>

          <h2 class="font-weight-light mb-3 text-uppercase">Bérlet</h2>
          <p class="text-caption font-weight-regular">
            A vásárolt bérletek nem átruházhatóak és nem becserélhetőek másik
            szolgáltatásra!
          </p>
        </v-sheet>
        <v-sheet
          max-width="20%"
          class="mx-auto text-caption black--text text-center"
          color="white"
        >
          <v-divider class="black"></v-divider>
        </v-sheet>
      </v-col>
    </v-row>
  </div>
</template>

<script>
export default {
  name: "Hazirend",
  data: () => ({
  }),

};
</script>
<style lang="scss" scoped>

</style>
