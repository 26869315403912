<template>
  <div id="arlista">
    <v-row class="text-center">
      <v-col ref="arlista">
        <!-- <h2 class="mx-auto text-uppercase mt-2 mb-6">Árlista</h2> -->
        <v-sheet
          class="mx-auto"
          min-height="600"
          max-height="750"
          width="400"
          max-width="400"
        >
          <v-carousel
            height="640"
            show-arrows-on-hover
            hide-delimiter-background
            class="mt-10"
            cycle
            interval="7500"
          >
            <!-- <v-carousel-item
              src="@/assets/arlista/1.jpg"
              cover
              @click="bigimg(1)"
            ></v-carousel-item>
            <v-carousel-item
              src="@/assets/arlista/2.jpg"
              cover
              @click="bigimg(2)"
            ></v-carousel-item>
            <v-carousel-item
              src="@/assets/arlista/3.jpg"
              cover
              @click="bigimg(3)"
            ></v-carousel-item>
            <v-carousel-item
              src="@/assets/arlista/4.jpg"
              cover
              @click="bigimg(4)"
            ></v-carousel-item>
            <v-carousel-item
              src="@/assets/arlista/5.jpg"
              cover
              @click="bigimg(5)"
            ></v-carousel-item>
            <v-carousel-item
              src="@/assets/arlista/6.jpg"
              cover
              @click="bigimg(6)"
            ></v-carousel-item> -->
            <v-carousel-item src="@/assets/arlista/1.png" cover></v-carousel-item>
            <v-carousel-item src="@/assets/arlista/2.png" cover></v-carousel-item>
            <v-carousel-item src="@/assets/arlista/3.png" cover></v-carousel-item>
            <v-carousel-item src="@/assets/arlista/4.png" cover></v-carousel-item>
            <v-carousel-item src="@/assets/arlista/5.png" cover></v-carousel-item>
            <v-carousel-item src="@/assets/arlista/6.png" cover></v-carousel-item>
            <v-carousel-item src="@/assets/arlista/7.png" cover></v-carousel-item>
          </v-carousel>
        </v-sheet>
        <!-- <div class="text-center">
          <v-dialog v-model="dialog" width="650">
            <v-img
              src="@/assets/arlista/1.jpg"
              v-if="selectedimg == 1"
              contain
              max-height="820"
            ></v-img>

            <v-img
              src="@/assets/arlista/2.jpg"
              v-if="selectedimg == 2"
              contain
              max-height="820"
            ></v-img>

            <v-img
              src="@/assets/arlista/3.jpg"
              v-if="selectedimg == 3"
              contain
              max-height="820"
            ></v-img>

            <v-img
              src="@/assets/arlista/4.jpg"
              v-if="selectedimg == 4"
              contain
              max-height="820"
            ></v-img>

            <v-img
              src="@/assets/arlista/5.jpg"
              v-if="selectedimg == 5"
              contain
              max-height="820"
            ></v-img>

            <v-img
              src="@/assets/arlista/6.jpg"
              v-if="selectedimg == 6"
              contain
              max-height="820"
            ></v-img>
          </v-dialog>
        </div> -->

        <v-col class="mb-4"
          ><v-sheet
            max-width="25%"
            class="mx-auto mt-16 text-caption black--text text-center"
            color="white"
          >
            <v-divider class="black"></v-divider>
          </v-sheet>
        </v-col>
      </v-col>
    </v-row>
  </div>
</template>

<script>
export default {
  name: "Arlista",
  data: () => ({
    selectedimg: 0,
    dialog: false,
  }),
};
</script>

<style lang="scss" scoped></style>
