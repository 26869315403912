<template>
  <div id="kezelesek">
    <!-- KEZELÉSEK -->
    <v-row class="text-center" >
      <v-col cols="12" ref="kezelesek">
        <!-- <h2 class="mx-auto font-weight-bold text-uppercase">Kezelések</h2> -->

        <v-col></v-col>
        <v-sheet class="mx-auto text-caption text-center pb-10">
          <h2 class="text-uppercase font-weight-light">Testkezelések</h2>
          <div class="mb-6 zarojel">
            (Izomépítés, fogyás, feszesítés, narancsbőr és stria eltávolítás,
            alakformálás, szétnyílt hasizom kezelése.)
          </div>
          <!-- Ez a RF felugró -->
          <div class="text-center pb-2">
            <h3
              color="#8b6c57"
              @click="sheet = !sheet"
              class="gomb text-uppercase font-weight-light"
            >
              Rádiófrekvenciás kezelés
            </h3>
            <v-bottom-sheet v-model="sheet" persistent>
              <v-card class="text-center">
                <v-card-text>
                  <!-- Ez a felugróban a bezáró gomb -->
                  <v-btn outlined class="mt-6" color="error" @click="sheet = !sheet">
                    Bezár
                  </v-btn>
                  <!-- Ez a felugró belseje -->
                  <div class="my-3">
                    <h3 class="pb-4 font-weight-light text-uppercase">Rádiófrekvenciás kezelés</h3>
                    <p justify="center" class="text-caption">
                      Bipoláris rádiófrekvenciás zsírbontás (RF) kiegészül infralámpával
                      és pulzáló vákuum masszázzsal. A rádiófrekvenciás kezelés és a
                      fényterápia (a stimuláló vörös fény) kombinációjával kiemelkedő
                      eredményeket érünk el. Az elektromágneses energiával átjárhatóvá
                      tesszük a célzott területket sejtek anyagcseréje felfokozódik és
                      elindulnak a méreganyagok ürülése is a nyirokrendszeren keresztül.
                      Aktiváljuk a kollagén termelődését és az elasztikus rostok
                      összehúzódásával javítjuk a bőrfeszességet.<br /><strong
                        >Narancsbőr csökkentésére és bőrfeszesítésre.</strong
                      ><br />
                    </p>
                    <v-img
                      :src="require('@/assets/szolgaltatasok/radiofrekvencias.jpg')"
                      contain
                      height="250"
                    />
                  </div>
                </v-card-text>
              </v-card>
            </v-bottom-sheet>
          </div>
          <!-- Ez a felugró vége -->

          <!-- Ez a vákumos felugró -->
          <div class="text-center pb-2">
            <h3
              color="#dfa110"
              @click="sheet16 = !sheet16"
              class="gomb text-uppercase font-weight-light"
            >
              Vákumos kezelés
            </h3>
            <v-bottom-sheet v-model="sheet16" persistent>
              <v-card class="text-center">
                <v-card-text>
                  <!-- Ez a felugróban a bezáró gomb -->
                  <v-btn outlined class="mt-6" color="error" @click="sheet16 = !sheet16">
                    Bezár
                  </v-btn>
                  <!-- Ez a felugró belseje -->
                  <div class="my-6">
                    <h3 class="pb-4 font-weight-light text-uppercase">Vákumos kezelés</h3>
                    <!-- <h4 class="pb-6">Vákumos kezelés</h4> -->
                    <p justify="center" class="text-caption">
                      Amellett, hogy a kezelés magában foglalja a rádiófrekvencia minden
                      előnyét, hiszen a vákuumos kezelőfejben beleépítve megtalálható, a
                      vákuummal végzett eljárás elősegíti a narancsbőr csökkenését,
                      természetesen zsírbontó hatása mellett. Joggal tarthatunk a vákuumos
                      kezelés után a bőr bekékülésétől, ez az eljárás azonban teljesen
                      kíméletesen lazítja fel a bőr letapadt szöveteit, így nincs ok az
                      aggodalomra. Egyéb zsírbontó eljárások kiváló kiegészítője lehet
                      mindazoknak, akik fogyni vágynak és narancsbőr problémájuk is van.
                    </p>
                    <v-img
                      :src="require('@/assets/szolgaltatasok/radiofrekvencias.jpg')"
                      contain
                      height="250"
                    />
                  </div>
                </v-card-text>
              </v-card>
            </v-bottom-sheet>
          </div>
          <!-- Ez a felugró vége -->

          <!-- Ez a kavitációs felugró -->
          <div class="text-center pb-2">
            <h3
              color="#dfa110"
              @click="sheet2 = !sheet2"
              class="gomb text-uppercase font-weight-light"
            >
              Kavitációs testkezelés
            </h3>
            <v-bottom-sheet v-model="sheet2" persistent>
              <v-card class="text-center">
                <v-card-text>
                  <!-- Ez a felugróban a bezáró gomb -->
                  <v-btn outlined class="mt-6" color="error" @click="sheet2 = !sheet2">
                    Bezár
                  </v-btn>
                  <!-- Ez a felugró belseje -->
                  <div class="my-6">
                    <h3 class="pb-4 font-weight-light text-uppercase">Kavitációs testkezelés</h3>
                    <!-- <h4 class="pb-6">Kavitációs testkezelés</h4> -->
                    <p justify="center" class="text-caption">
                      Kavitációs zsírbontással egy kezelés során egy testtájék 3-6 percen
                      keresztül kezelhető. A kezelési időt illetve a kezelések közötti
                      napok számát a zsírszövet vastagsága határozza meg. A kezelések
                      végére a zsírpárnák a felére csökkennek, a bőr feszessé és simává, a
                      test kontúr jól formálttá válik.
                    </p>
                    <v-img
                      class="mt-6 mb-6"
                      :src="require('@/assets/szolgaltatasok/kavitacios.jpg')"
                      contain
                      height="250"
                    />
                  </div>
                </v-card-text>
              </v-card>
            </v-bottom-sheet>
          </div>
          <!-- Ez a felugró vége -->

          <!-- Ez a lipolézeres felugró -->
          <div class="text-center pb-2">
            <h3
              color="#dfa110"
              @click="sheet3 = !sheet3"
              class="gomb text-uppercase font-weight-light"
            >
              4D Lipolézeres zsírbontás
            </h3>
            <v-bottom-sheet v-model="sheet3" persistent>
              <v-card class="text-center">
                <v-card-text>
                  <!-- Ez a felugróban a bezáró gomb -->
                  <v-btn outlined class="mt-6" color="error" @click="sheet3 = !sheet3">
                    Bezár
                  </v-btn>
                  <!-- Ez a felugró belseje -->
                  <div class="my-6">
                    <h3 class="pb-4 font-weight-light text-uppercase">4D Lipolézeres zsírbontás</h3>
                    <!-- <h4 class="pb-6">4D Lipolézeres zsírbontás</h4> -->
                    <p class="text-caption font-weight-regular">
                      A 4D műtétmentes lézeres zsírleszívás technológiának köszönhetően a
                      készülék egy időben négy különböző mélységben kezeli a zsírszövetet,
                      így látványosabb és gyorsabb eredményt lehet elérni különböző
                      testalkatú vendégeknél.
                    </p>
                    <v-img
                      class="mt-6 mb-6"
                      :src="require('@/assets/szolgaltatasok/lipolezeres.jpg')"
                      contain
                      height="250"
                    />
                  </div>
                </v-card-text>
              </v-card>
            </v-bottom-sheet>
          </div>
          <!-- Ez a felugró vége -->

          <!-- Ez az enzimes mezo felugró -->
          <div class="text-center pb-2">
            <h3
              color="#dfa110"
              @click="sheet6 = !sheet6"
              class="gomb text-uppercase font-weight-light"
            >
              Enzimes mezoterápia
            </h3>
            <v-bottom-sheet v-model="sheet6" persistent>
              <v-card class="text-center">
                <v-card-text>
                  <!-- Ez a felugróban a bezáró gomb -->
                  <v-btn outlined class="mt-6" color="error" @click="sheet6 = !sheet6">
                    Bezár
                  </v-btn>
                  <!-- Ez a felugró belseje -->
                  <div class="my-6">
                    <h3 class="pb-4 font-weight-light text-uppercase">Enzimes mezoterápia</h3>
                    <!-- <h4 class="pb-6">Enzimes mezoterápia</h4> -->
                    <p justify="center" class="text-caption">
                      Bőrünk feszességét az irharétegben jelenlévő kollagén, rugalmasságát
                      pedig az elasztin adja. Az idő múlásával azonban ez veszít
                      vízmegkötő képességéből, így kialakulnak a ráncok és a szarkalábak.
                      A tűs mezoterápiás kezeléssel az apró tűk mikrosérüléseket okoznak a
                      bőr felszínén, mely szabad szemmel nem látható. Ekkor a szervezet
                      öngyógyító folyamatba kezd: kollagént és elasztint kezd el termelni,
                      így a ráncok feltöltődnek, a bőr feszesebbé válik. Ezt kollagén
                      indukciós terápiának nevezzük. Mezoterápiás kezelés során orvosi
                      tisztaságú hatóanyagokkal dolgozom. A tűk által ejtett kis
                      csatornákon keresztül lényegesen mélyebbre juttatom be a
                      hatóanyagokat, így érve el azonnali, látványos hatást. Akár 40%-al
                      hatékonyabb bőrfiatalítás, mint egy átlagos arckezelésnél! A
                      különböző hatóanyag koktélokat bőrdiagnosztizálás után személyre
                      szabottan állítom össze. A kezelést kúraszerűen ajánlom.
                    </p>
                    <v-img
                      class="mt-6 mb-6"
                      :src="require('@/assets/szolgaltatasok/vakum.jpg')"
                      contain
                      height="250"
                    />
                  </div>
                </v-card-text>
              </v-card>
            </v-bottom-sheet>
          </div>
          <!-- Ez a felugró vége -->

          <h2 class="text-uppercase pt-8 font-weight-light">Elektrokozmetikai arckezelések</h2>
          <!-- <h1 class="mt-8">Elektrokozmetikai kezelések</h1> -->
          <div class="mb-6 text-blue">
            <span class="zarojel">(Ráncok eltávolítása, problémás bőr kezelése, arc feltöltése orvosi tisztaságú
            hatóanyagokkal.)</span>
          </div>
          <!-- Ez az enzimes felugró -->
          <div class="text-center pb-2">
            <h3
              color="#dfa110"
              @click="sheet7 = !sheet7"
              class="gomb text-uppercase font-weight-light"
            >
              Enzimes elektroporációs arckezelés
            </h3>
            <v-bottom-sheet v-model="sheet7" persistent>
              <v-card class="text-center">
                <v-card-text>
                  <!-- Ez a felugróban a bezáró gomb -->
                  <v-btn outlined class="mt-6" color="error" @click="sheet7 = !sheet7">
                    Bezár
                  </v-btn>
                  <!-- Ez a felugró belseje -->
                  <div class="my-6">
                    <h3 class="pb-4 font-weight-light text-uppercase">Enzimes elektroporációs arckezelés</h3>
                    <!-- <h4 class="pb-4">Enzimes elektroporációs arckezelés</h4> -->
                    <p justify="center" class="text-caption">
                      Az Elektroporáció olyan bőrfiatalító és bőrmegújító eljárás, mely
                      serkenti a kollagén- és az elasztikus rostok szintézisét, így
                      jelentős mértékben lassítja a bőröregeddést, új megoldás az orvosi
                      tisztaságú hatóanyagok bőrbe juttatására.<br />
                      Egyszerűen, fájdalommentesen és hatékonyan. <br /><br />
                      <h4 class="font-weight-light text-uppercase">A kezelés folyamata:</h4><br />
                      <span class="text-caption">
                      1. Tisztítás<br />
                      2. Mikrodermabrázió<br />
                      3. Hidrodermabrázió<br />
                      4. Enzimes elektroporációs kezelés<br />
                      5. Befejező krém</span>
                    </p>
                  </div>
                </v-card-text>
              </v-card>
            </v-bottom-sheet>
          </div>
          <!-- Ez a felugró vége -->

          <!-- Ez az enzimes oxigén felugró -->
          <div class="text-center pb-2">
            <h3
              color="#dfa110"
              @click="sheet5 = !sheet5"
              class="gomb text-uppercase font-weight-light"
            >
              Enzimes oxigénterápiás arckezelés
            </h3>
            <v-bottom-sheet v-model="sheet5" persistent>
              <v-card class="text-center">
                <v-card-text>
                  <!-- Ez a felugróban a bezáró gomb -->
                  <v-btn outlined class="mt-6" color="error" @click="sheet5 = !sheet5">
                    Bezár
                  </v-btn>
                  <!-- Ez a felugró belseje -->
                  <div class="my-6">
                    <h3 class="pb-4 font-weight-light text-uppercase">Enzimes oxigénterápiás arckezelés</h3>
                    <!-- <h4 class="pb-4">Enzimes oxigénterápiás arckezelés</h4> -->
                    <p justify="center" class="text-caption">
                      Nagyhatású oxigén- és hatóanyag infúzió a fáradt, fakó bőrnek. Már
                      az első oxigén kezelés különösen látványos, megfiatalítja az
                      arcbőrt!<br />
                      A koncentrált oxigén a legfontosabb életelixír! Ez a forradalmi
                      arckezelés MINDEN bőrtípusra alkalmazható. A bőrbe oxigént, növényi
                      eredetű őssejteket, peptideket, nagy koncentrációjú enimes növényi
                      anyagokat visz.<br />
                      A bőr ragyogó, friss és fiatalos lesz!<br /><br />
                      <h4 class="font-weight-light text-uppercase">A kezelés folyamata:</h4><br />
                      <span class="text-caption">
                      1. Tisztítás <br />2. Mikrodermabrázió<br />
                      3. Hatóanyagos oxigén infúzió: tű nélkül, a magasnyomású oxigén
                      segítségével juttatjuk be az aktív szérumokat<br />
                      4. Bejefező krém</span>
                    </p>
                  </div>
                </v-card-text>
              </v-card>
            </v-bottom-sheet>
          </div>
          <!-- Ez a felugró vége -->

          <!-- Ez az rádiófrekis felugró -->
          <div class="text-center pb-2">
            <h3
              color="#dfa110"
              @click="sheet8 = !sheet8"
              class="gomb text-uppercase font-weight-light"
            >
              Rádiófrekvenciás kezelés
            </h3>
            <v-bottom-sheet v-model="sheet8" persistent>
              <v-card class="text-center">
                <v-card-text>
                  <!-- Ez a felugróban a bezáró gomb -->
                  <v-btn outlined class="mt-6" color="error" @click="sheet8 = !sheet8">
                    Bezár
                  </v-btn>
                  <!-- Ez a felugró belseje -->
                  <div class="my-6">
                    <h3 class="pb-4 font-weight-light text-uppercase">Rádiófrekvenciás kezelés</h3>
                    <!-- <h4 class="pb-4">Rádiófrekvenciás kezelés</h4> -->
                    <p justify="center" class="text-caption">
                      A rádiófrekvnciás arckezelés során a hő hatására beindul a kollagén
                      szintézis, a keringés jabvul, így az arcbőr feszesebbé, erősebbé és
                      tömörebbé válik.<br />
                      Egy kezeléssorozat eredményeként halványulnak a ráncok, csökken a
                      pórusméret, javul a bőr állaga. A kollagén újratermelődésének
                      köszönhetően pedig késleltetjük az öregedési folyamatokat.<br /><br />
                      <h4 class="font-weight-light text-uppercase">A kezelés folyamata:</h4><br />
                      <span class="text-caption">
                      1. Tisztítás<br />
                      2. Mikrodermabrázió<br />
                      3. Arcfeszesítés rádiófrekvenciával<br />
                      4. Hideg-meleg terápiás vasalás<br />
                      5. Befejező krém</span>
                    </p>
                  </div>
                </v-card-text>
              </v-card>
            </v-bottom-sheet>
          </div>
          <!-- Ez a felugró vége -->

          <!-- Ez az relax felugró -->
          <div class="text-center pb-2">
            <h3
              color="#dfa110"
              @click="sheet9 = !sheet9"
              class="gomb text-uppercase font-weight-light"
            >
              Relax kezelés
            </h3>
            <v-bottom-sheet v-model="sheet9" persistent>
              <v-card class="text-center">
                <v-card-text>
                  <!-- Ez a felugróban a bezáró gomb -->
                  <v-btn outlined class="mt-6" color="error" @click="sheet9 = !sheet9">
                    Bezár
                  </v-btn>
                  <!-- Ez a felugró belseje -->
                  <div class="my-6">
                    <h3 class="pb-4 font-weight-light text-uppercase">Relax kezelés</h3>
                    <!-- <h4 class="pb-4">Relax kezelés</h4> -->
                    <p justify="center" class="text-caption">
                      Kényeztesd magad egy tápláló és vitaminokban gazdag arckezeléssel. A
                      kellemes masszázs segít megteremteni a test és a lélek egyensúlyát,
                      miközben mélyen ápolja és hidratálja az arcbőrt.<br /><br />
                      <h4 class="font-weight-light text-uppercase">A kezelés folyamata:</h4><br />
                      <span class="text-caption">
                      1. Tisztítás<br />
                      2. Hidrodermabrázió<br />
                      3. Arc-, nyak-, dekoltázsmassázs<br />
                      4. Vitaminos krém
                    </span>
                    </p>
                  </div>
                </v-card-text>
              </v-card>
            </v-bottom-sheet>
          </div>
          <!-- Ez a felugró vége -->

          <!-- Ez az mélytisztító felugró -->
          <div class="text-center pb-2">
            <h3
              color="#dfa110"
              @click="sheet10 = !sheet10"
              class="gomb text-uppercase font-weight-light"
            >
              Mélytisztító vákumos kezelés
            </h3>
            <v-bottom-sheet v-model="sheet10" persistent>
              <v-card class="text-center">
                <v-card-text>
                  <!-- Ez a felugróban a bezáró gomb -->
                  <v-btn outlined class="mt-6" color="error" @click="sheet10 = !sheet10">
                    Bezár
                  </v-btn>
                  <!-- Ez a felugró belseje -->
                  <div class="my-6">
                    <h3 class="pb-4 font-weight-light text-uppercase">Mélytisztító vákumos kezelés</h3>
                    <!-- <h4 class="pb-4">Mélytisztító vákumos kezelés</h4> -->
                    <p justify="center" class="text-caption">
                      Fájdalommentesen távolítja el a faggyút a pórusokból. Az erőteljes,
                      vákuummal kombinált kezelőfej nem csupán hámlaszt, de egy időben
                      mikrodermabráziót és mély rétegű bőrtisztítást is végez.<br />
                      A fej spriál alakban juttatja a tisztító szalicilsavas folyadékot a
                      bőrre, hogy feloldja a szennyeződéseket, az elhalt hámsejteket, a
                      faggyúr és a komedót, az 5 fokozatban állítható vákuum pedig
                      tökéletesen eltávolít minden felesleget. Az eredmény tökéletesen
                      tiszta, ragyogó fiatalos arcbőr.<br /><br />
                      <h4 class="font-weight-light text-uppercase">A kezelés folyamata:</h4><br />
                      <span class="text-caption">
                      1. Tisztítás<br />
                      2. Hidrodermabrázió<br />
                      3. Vákumos mélytisztítás: mélytisztítás nyomkodás nélkül, a vákum
                      erejével<br />
                      4. Befejező szérum
                    </span>
                    </p>
                  </div>
                </v-card-text>
              </v-card>
            </v-bottom-sheet>
          </div>
          <!-- Ez a felugró vége -->

          <h2 class="text-uppercase pt-8 font-weight-light">Masszázs</h2>
          <!-- <h1 class="mt-8">Masszázs</h1> -->
          <div class="mb-4"></div>
          <!-- Ez az cellulit felugró -->
          <div class="text-center pb-2">
            <h3
              color="#dfa110"
              @click="sheet11 = !sheet11"
              class="gomb text-uppercase font-weight-light"
            >
              Cellulit mélyfogyasztó masszázs
            </h3>
            <v-bottom-sheet v-model="sheet11" persistent>
              <v-card class="text-center">
                <v-card-text>
                  <!-- Ez a felugróban a bezáró gomb -->
                  <v-btn outlined class="mt-6" color="error" @click="sheet11 = !sheet11">
                    Bezár
                  </v-btn>
                  <!-- Ez a felugró belseje -->
                  <div class="my-6">
                    <h3 class="pb-4 font-weight-light text-uppercase">Cellulit mélyfogyasztó masszázs</h3>
                    <!-- <h4 class="pb-4">Cellulit mélyfogyasztó masszázs</h4> -->
                    <p justify="center" class="text-caption">
                      A cellulit masszázs a zsírszöveteket meglazítja, megkönnyítve ezzel
                      a lebontásukat a szervezet számára. Hatására a kapillárisok
                      (hajszálerek) aktivizálódnak, vérbőség keletkezik, a bőr
                      hőmérséklete emelkedik, ezáltal olyan kémiai anyagok termelődnek,
                      melyeknek értágító hatásuk van, emiatt élénkül a vérkeringés. Ez a
                      szövetek jobb táplálásához vezet, a sejtek anyagcseréje is
                      felgyorsul.
                    </p>
                  </div>
                </v-card-text>
              </v-card>
            </v-bottom-sheet>
          </div>
          <!-- Ez a felugró vége -->

          <!-- Ez az Köpöly felugró -->
          <div class="text-center pb-2">
            <h3
              color="#dfa110"
              @click="sheet12 = !sheet12"
              class="gomb text-uppercase font-weight-light"
            >
              Köpöly
            </h3>
            <v-bottom-sheet v-model="sheet12" persistent>
              <v-card class="text-center">
                <v-card-text>
                  <!-- Ez a felugróban a bezáró gomb -->
                  <v-btn outlined class="mt-6" color="error" @click="sheet12 = !sheet12">
                    Bezár
                  </v-btn>
                  <!-- Ez a felugró belseje -->
                  <div class="my-6">
                    <h3 class="pb-4 font-weight-light text-uppercase">Köpöly</h3>
                    <!-- <h4 class="pb-4">Köpöly</h4> -->
                    <p justify="center" class="text-caption">
                      A módszer lényege, hogy a bőrre helyezett edényekben vákuumot
                      képeznek, ez felfelé szívja a bőrt és beindítja a jótékony
                      folyamatokat. A kb. 2-7 cm közötti edényeket 5-15 percig hagyják
                      rajta a kezelendő testrészen, mint pl. a háton, a vállon, majd
                      óvatos mozdulatokkal eltávolítják. A köpölyözés hatására a bőrön
                      jellegzetes, kerek, piros vagy barnás foltok keletkezhetnek, melyek
                      általában néhány napon belül elmúlnak. A köpölyözés segíti a
                      szervezet öngyógyító folyamatait, fokozza a vér-, illetve a
                      nyirokáramlást, így a méreganyagoktól is könnyebben szabadulhatunk
                      meg. Nagyszerűen használható kiegészítő terápiaként például olyan
                      betegségek esetén, mint a krónikus bronchitis, a krónikus köhögés, a
                      tüdőgyulladás, illetve a vázizomzatot érintő betegségek, problémák.
                      Ezen kívül javítja a szervezet energiaellátását, kiegyensúlyozó és
                      méregtelenítő hatású, és segít kezelni az olyan krónikus
                      fájdalmakat, mint a reumatikus panaszok és a migrén.
                    </p>
                  </div>
                </v-card-text>
              </v-card>
            </v-bottom-sheet>
          </div>
          <!-- Ez a felugró vége -->

          <!-- Ez az Izomlazító relaxáló masszázs felugró -->
          <div class="text-center pb-2">
            <h3
              color="#dfa110"
              @click="sheet13 = !sheet13"
              class="gomb text-uppercase font-weight-light"
            >
              Izomlazító relaxáló masszázs
            </h3>
            <v-bottom-sheet v-model="sheet13" persistent>
              <v-card class="text-center">
                <v-card-text>
                  <!-- Ez a felugróban a bezáró gomb -->
                  <v-btn outlined class="mt-6" color="error" @click="sheet13 = !sheet13">
                    Bezár
                  </v-btn>
                  <!-- Ez a felugró belseje -->
                  <div class="my-6">
                    <h3 class="pb-4 font-weight-light text-uppercase">Izomlazító relaxáló masszázs</h3>
                    <!-- <h4 class="pb-4">Izomlazító relaxáló masszázs</h4> -->
                    <p justify="center" class="text-caption">
                      A test kötött, feszes izmainak oldása, az izmok felszabadítása,
                      lazítás, ízületek kimozgatása, fájó pontok oldása. Nagyon jó
                      regeneráló, fájdalomcsillapító és méregtelenítő hatású masszázs. A
                      masszázs a gyógyító érintés tudománya. Egyike a legősibb
                      fizioterápiás gyógymódoknak. Olyan tevékenység, amelyet fizikai
                      fájdalmak esetén ösztönösen is alkalmazunk. A masszázs az érintésen
                      keresztül teremti meg a testi-lelki egyensúlyt. Az izomlazító
                      masszázs a svédmasszázs fogásrendszerével dolgozó masszázs, illetve
                      annak egy jóval erőteljesebb, intenzívebb, a mélyizomzatra kiható
                      változata. Ez a masszázs a feszülő, letapadt, merev, görcsös
                      izmokat, myalgiás csomókat felszabadítsa, ezáltal azok
                      rugalmasabbak, ellenállóbbak, terhelhetőbbek lesznek.
                    </p>
                  </div>
                </v-card-text>
              </v-card>
            </v-bottom-sheet>
          </div>
          <!-- Ez a felugró vége -->

          <!-- Ez az Vákumos izomlazítás/zsírtörés felugró -->
          <div class="text-center pb-2">
            <h3
              color="#dfa110"
              @click="sheet14 = !sheet14"
              class="gomb text-uppercase font-weight-light"
            >
              Vákumos izomlazítás/zsírtörés
            </h3>
            <v-bottom-sheet v-model="sheet14" persistent>
              <v-card class="text-center">
                <v-card-text>
                  <!-- Ez a felugróban a bezáró gomb -->
                  <v-btn outlined class="mt-6" color="error" @click="sheet14 = !sheet14">
                    Bezár
                  </v-btn>
                  <!-- Ez a felugró belseje -->
                  <div class="my-6">
                    <h3 class="pb-4 font-weight-light text-uppercase">Vákumos izomlazítás/zsírtörés</h3>
                    <!-- <h4 class="pb-4">Vákumos izomlazítás/zsírtörés</h4> -->
                    <p justify="center" class="text-caption">
                      A vákuummasszázs a kézi nyirokmasszázs egy speciális formája. Az
                      eljárás egyesíti magában a hagyományos köpölyözést és a
                      nyirokmasszázst. Vérkeringést, nyirokkeringést javító, és
                      immunrendszert aktiváló hatását többféle probléma esetén is
                      felhasználhatjuk. Ez a sajátos technika felfokozza a nyirokáramlást,
                      és a vérkeringést. A nyirokkeringés akár ötvenszeresére is
                      felgyorsulhat, ami biztosítja a salakanyagok elszállítását a
                      szervezetből. A vákuum vérbőségkeltő hatása az anyagcsere
                      élénküléséhez és a salakanyagok jobb lebontásához vezet. A
                      salakanyagok tehát a legtermészetesebb úton távoznak a szervezetből,
                      sok folyadék segítségével. A kezelés hatására felgyorsul a
                      vérkeringés is, ezáltal javul a bőr alatti kötőszövetek
                      oxigénellátása és anyagcseréje. A felgyorsult keringés következtében
                      erősödik a szervezet zsírégetései folyamata. A vákuum feszítő hatása
                      révén a zsírsejtek jelentős részének burka áteresztővé válik, így a
                      zsír kifolyik belőlük és a nyirokkeringésen keresztül vesén át
                      távozik.
                    </p>
                  </div>
                </v-card-text>
              </v-card>
            </v-bottom-sheet>
          </div>
          <!-- Ez a felugró vége -->

          <h2 class="text-uppercase pt-8 font-weight-light">Kardió edzés</h2>
          <div class="mb-6"></div>
          <!-- Ez az Infratrainer felugró -->
          <div class="text-center pb-2">
            <h3
              color="#dfa110"
              @click="sheet4 = !sheet4"
              class="gomb text-uppercase font-weight-light"
            >
              Infratrainer
            </h3>
            <v-bottom-sheet v-model="sheet4" persistent>
              <v-card class="text-center">
                <v-card-text>
                  <!-- Ez a felugróban a bezáró gomb -->
                  <v-btn outlined class="mt-6" color="error" @click="sheet4 = !sheet4">
                    Bezár
                  </v-btn>
                  <!-- Ez a felugró belseje -->
                  <div class="my-6">
                    <h3 class="pb-4 font-weight-light text-uppercase">Infratrainer</h3>
                    <!-- <h4 class="pb-4">Infratrainer</h4> -->
                    <p justify="center" class="text-caption">
                      Az INFRATRAINER alapjaiban változtatja meg a fogyás, alakformálás,
                      narancsbőrkezelés, és méregtelenítés koncepcióját. Minden eddiginél
                      gyorsabb, hatékonyabb megoldást kínál felhasználóinknak. Az
                      INFRATRAINER hatékony testmozgást biztosít, függetlenül a jelenlegi
                      edzettségi szinttől és észrevehető változásokat eredményez már
                      néhány héten belül! Az edzés során az infravörös sugárzás
                      felmelegíti és feloldja a zsírszöveteket belülről, és felszabadítja
                      a méreganyagokat. A súlycsökkenés 20% -a zsír, amely kiemelkedő más
                      edzési módszerekhez képest, mint például az intenzív futás. A
                      súlycsökkenés mellett a méregtelenítő hatás csak rendkívül
                      megterhelő sportokkal hasonlítható össze, mint például a maratoni
                      futás.
                    </p>
                    <v-img
                      class="mt-6 mb-6"
                      :src="require('@/assets/szolgaltatasok/infratrainer.jpg')"
                      contain
                      height="250"
                    />
                  </div>
                </v-card-text>
              </v-card>
            </v-bottom-sheet>
          </div>
          <!-- Ez a felugró vége -->
        </v-sheet>
        <v-sheet
          max-width="20%"
          class="mx-auto mt-5 text-caption black--text text-center"
          color="white"
        >
          <v-divider color="black"></v-divider>
        </v-sheet>
      </v-col>
    </v-row>
  </div>
</template>

<script>
export default {
  name: "Kezelesek",
  data: () => ({
    sheet: false,
    sheet2: false,
    sheet3: false,
    sheet4: false,
    sheet5: false,
    sheet6: false,
    sheet7: false,
    sheet8: false,
    sheet9: false,
    sheet10: false,
    sheet11: false,
    sheet12: false,
    sheet13: false,
    sheet14: false,
    sheet15: false,
    sheet16: false,
    sheet20: false,


  }),

  methods: {
    hazirendClick() {
      this.$refs.hazirend.scrollIntoView({ behavior: "smooth" });
    },
    kezelesekClick() {
      this.$refs.kezelesek.scrollIntoView({ behavior: "smooth" });
    },
    arlistaClick() {
      this.$refs.arlista.scrollIntoView({ behavior: "smooth" });
    },
    elerhetosegekClick() {
      this.$refs.elerhetosegek.scrollIntoView({ behavior: "smooth" });
    },
  },
};
</script>


<style lang="scss" scoped>
body {
  position: relative;
  overflow: hidden;
}
.contact:hover {
  color: blue;
}
.gomb {
  color: #c2a38e;
  // color: #daca99;
}
.gomb:hover {
  cursor: pointer;
  color: #58471f;
}
.zarojel {
color: #a8a8a8;
}

</style>
